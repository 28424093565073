import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router";
import { loadLogo } from "../services/BrandLogoService";
const BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

export default function SecureRoutes() {
  const [token, setToken] = useState(Cookies.get("tkn"));
  const [user, setUser] = useState();
  const navigate = useNavigate();
  const [activeAccount, setActiveAccount] = useState(false);
  const [isAccountPremium, setIsAccountPremium] = useState(false);
  const [subscriptionCancelAt, setSubscriptionCancelAt] = useState();

  const isUserAuthenticated = useEffect(() => {
    if (!token || token === "") {
      navigate("/entrar?refresh=true");
      return;
    }

    let info = jwtDecode(token);
    if (info.exp * 1000 < new Date().valueOf()) {
      navigate("/entrar?refresh=true");
      return;
    }
    loadUser();
  }, []);

  const watchActiveAccount = useEffect(() => {
    if (!token || !user) return;

    const checkForActiveAccount = async () => {
      if (user.authority === "ADMIN") {
        setActiveAccount(true);
        setIsAccountPremium(true);
        return;
      }
      const url = `${BASE_URL}subscription/session-status`;
      const res = await fetch(url, {
        headers: { Authorization: token },
      }).catch((err) => {
        console.log(err);
        navigate("/entrar?refresh=true");
      });
      let status = "";
      if (res.ok) {
        const subscription = await res.json();
        status = subscription.status;
        if (status === "active") {
          setActiveAccount(true);
          checkForPremiumAccount(subscription);
          setSubscriptionCancelAt(subscription.cancel_at);
        }
      }
    };
    checkForActiveAccount();
  }, [token, user]);
  const checkForPremiumAccount = (subscription) => {
    const BASIC_LOOKUP_KEY = "express_basic_plan";
    const lookupkey = subscription.items.data[0].price.lookup_key;
    if (lookupkey !== BASIC_LOOKUP_KEY) setIsAccountPremium(true);
  };
  const loadUser = async () => {
    try {
      let info = jwtDecode(token);
      const url = `${BASE_URL}user-collection/${info.userId}`;
      const res = await fetch(url, { headers: { Authorization: token } });
      if (res.ok) {
        const u = await res.json();
        const logoResource = await loadLogo(u.email, token);
        u.brand.logoURLTmp = logoResource.urlTmp;
        setUser(u);
      } else navigate("/entrar?refresh=true");
    } catch (err) {
      navigate("/entrar?refresh=true");
    }
  };
  return (
    <>
      {token && user && (
        <Outlet
          context={{
            token: token,
            user: user,
            setUser: setUser,
            activeAccount: activeAccount,
            isAccountPremium: isAccountPremium,
            subscriptionCancelAt: subscriptionCancelAt,
          }}
        />
      )}
    </>
  );
}
