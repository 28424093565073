import Resource from "../OBJRep/Resource";
import { toDataURL } from "./Utils";

const APP_DOMAIN = process.env.REACT_APP_DOMAIN;
const RESOURCES_BASE_URL = process.env.REACT_APP_RESOURCES_BASE_URL;

export const postLogo = async (file, email, token) => {
  const fileName = `${email}_LOGO.png`; // Specify your desired file name here
  const newFile = new File([file], fileName, { type: file.type });
  const formData = new FormData();
  formData.append("files", newFile);
  formData.append("tags", `${email}_LOGO`);
  const res = await fetch(`${RESOURCES_BASE_URL}/files`, {
    method: "POST",
    body: formData,
    headers: { Authorization: token },
  });
};
export const loadLogo = async (email, token) => {
  const logoLocation = `/resources/files/location/${email}_logo.png`;
  const url = await toDataURL(`${APP_DOMAIN}${logoLocation}`, token);
  console.log(url);
  return Resource(url, logoLocation, `${email}_logo`);
};

export const deleteLogo = async (email, token) => {};
