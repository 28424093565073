import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  createBrowserRouter,
  createHashRouter,
  RouterProvider,
} from "react-router-dom";

import Home from "./components/Views/Home";
import SecureRoutes, { userLoader } from "./components/SecureRoutes";
import RegisterPage from "./Views/Register";

import Wo from "./components/Views/wo";
import ClientsView from "./components/Views/Customers";
import LandingPageView from "./components/Views/Landing";
import "./App.css";
import PaymentPlansView from "./components/Views/PaymentPlans";
import NotFoundView from "./components/Views/NotFound";
import QuestionaryView from "./components/Views/Questionary";
import LoginView from "./components/Views/Login";
import SubscriptionView from "./components/Views/Subscription";
import PlanBasic from "./components/Views/PlanBasic";
import PaymentMethods from "./components/Views/PaymentMethods";
import EmailValidation from "./components/Views/EmailValidation";
import AccountRecovery from "./components/Views/AccountRecovery";

const router = createBrowserRouter([
  {
    path: "/",
    element: <SecureRoutes />,
    children: [
      {
        path: "/formulario",
        element: <QuestionaryView />,
      },
      {
        path: "/editor",
        element: <Wo />,
      },
      {
        path: "/encartes",
        element: <Home />,
        index: true,
      },
      {
        path: "/assinatura",
        element: <SubscriptionView />,
      },
      {
        path: "/planos",
        element: <PaymentPlansView />,
      },
      {
        path: "/metodo-pagamento",
        element: <PaymentMethods />,
      },
      {
        path: "/clientes",
        element: <ClientsView />,
      },
      {
        path: "/verificacao-email",
        element: <EmailValidation />,
      },
    ],
  },
  {
    path: "/recuperar-conta",
    element: <AccountRecovery />,
  },
  {
    path: "/registrar",
    element: <RegisterPage />,
  },

  {
    path: "/entrar",
    element: <LoginView />,
    index: true,
  },

  {
    path: "/",
    element: <LandingPageView />,
    index: true,
  },
  {
    path: "*",
    element: <NotFoundView />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);
