import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Logo from "../sharable/Logo";
import {
  InputWrapper,
  Modal,
  ModalHeader,
  TextLink,
} from "../../SharableStyles/FormStyle";
import { IonIcon } from "@ionic/react";
import { mailOpen, mailOpenOutline, mailOpenSharp } from "ionicons/icons";
import { Input } from "../sharable/InputStyle";
import Button from "../sharable/ActionButton";
import { emailRegex, passwordRegex, validateField } from "../../services/Utils";
import Error from "../sharable/Error";
import { AppearAnimation } from "../sharable/ModalPopup";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
const BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;
const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  header {
    padding: 2rem 4.2rem;
    display: flex;
    justify-content: start;
    width: 100%;
  }
`;

const Main = styled.main`
  align-items: center;
  justify-content: space-around;
  .mail-icon {
    font-size: 12rem;
  }
  p {
    font-size: 1.2rem;
  }
  gap: 3.2rem;
  padding: 0 2rem 2rem 2rem;

  .wrapper {
    margin-top: 2rem;
  }

  .email-input {
    min-width: 30rem;
  }
`;

const RecoveryModal = styled(Modal)`
  animation: ${AppearAnimation} 500ms linear;
`;
export default function AccountRecovery() {
  const [recoveryToken, setRecoveryToken] = useState();
  const [recoveryEmail, setRecoveryEmail] = useState();
  const [showCodeValidationModal, setShowCodeValidationModal] = useState(false);
  const [showPasswordUpdateModal, setShowPasswordUpdateModal] = useState(false);

  const [errorMessage, setErrorMessage] = useState();

  return (
    <Container>
      <header>
        <Logo showGoBackButton={true} path={"/entrar"} />
      </header>

      {showCodeValidationModal ? (
        <CodeValidationModal
          setRecoveryToken={setRecoveryToken}
          recoveryEmail={recoveryEmail}
          setShowCodeValidationModal={setShowCodeValidationModal}
          setShowPasswordUpdateModal={setShowPasswordUpdateModal}
        />
      ) : showPasswordUpdateModal ? (
        <PasswordUpdateModal
          recoveryToken={recoveryToken}
          recoveryEmail={recoveryEmail}
        />
      ) : (
        <RecoveryEmailModal
          setRecoveryEmail={setRecoveryEmail}
          recoveryEmail={recoveryEmail}
          setShowCodeValidationModal={setShowCodeValidationModal}
        />
      )}
    </Container>
  );
}

const CodeValidationModal = ({
  setShowPasswordUpdateModal,
  setShowCodeValidationModal,
  setRecoveryToken,
  recoveryEmail,
}) => {
  const [errorMessage, setErrorMessage] = useState();
  const [recoveryCode, setRecoveryCode] = useState();
  const watchRecoveryCodeInput = useEffect(() => {
    if (recoveryCode && recoveryCode.length === 6) {
      validateRecoveryCode();
    }
  }, recoveryCode);

  const validateRecoveryCode = async () => {
    if (!recoveryCode || recoveryCode.length < 6 || recoveryCode > 6) {
      setErrorMessage("Código deve ter 6 dígitos");
    }
    const res = await fetch(
      `${BASE_URL}account-recovery/${recoveryEmail}/${recoveryCode}`,
      { method: "POST" }
    );
    if (res.ok) {
      setShowCodeValidationModal(false);
      setShowPasswordUpdateModal(true);
      setRecoveryToken(await res.text());
    } else if (res.status === 401) {
      setErrorMessage("Código invalido");
    } else if (res.status === 404)
      setErrorMessage(
        `Não existe nenhuma conta vinculada ao email "${recoveryEmail}".`
      );
  };
  return (
    <Modal>
      <ModalHeader>
        <h1>código de recuperação</h1>
        <p>
          Digite o código que você recebeu no email para atualizar sua senha
        </p>
      </ModalHeader>
      <Main className="flex">
        <div>
          <div className="wrapper flex min-gap direction-column">
            {errorMessage && <Error message={errorMessage} />}
            <Input
              placeholder="000000"
              onInput={(e) => setRecoveryCode(e.target.value)}
            />

            <Button
              $fullWidth
              text={"verificar"}
              action={validateRecoveryCode}
            />
          </div>
        </div>{" "}
        <IonIcon icon={mailOpenOutline} className="mail-icon" />
      </Main>
    </Modal>
  );
};

const PasswordUpdateModal = ({ recoveryToken, recoveryEmail }) => {
  const [newPassword, setNewPassword] = useState();
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const navigate = useNavigate();
  const updatePassword = async () => {
    const newPasswordValidation = validateField(
      "Nova senha",
      newPassword,
      true,
      true,
      6,
      8,
      passwordRegex
    );
    let areFieldsValid = true;
    let message = "";
    if (!newPasswordValidation.isValid) {
      areFieldsValid = false;
      message += newPasswordValidation.message;
    }

    if (newPassword !== newPasswordConfirmation) {
      areFieldsValid = false;
      message += "As senhas devem ser iguais.";
    }

    if (!areFieldsValid) setErrorMessage(message);
    else {
      const body = {
        email: recoveryEmail,
        password: newPassword,
      };
      console.log(recoveryToken);
      console.log(jwtDecode(recoveryToken).userId);
      const res = await fetch(`${BASE_URL}auth/basic/password-update`, {
        method: "PUT",
        headers: {
          Authorization: recoveryToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      if (res.ok) {
        setErrorMessage("");
        setSuccessMessage(
          "Sua senha foi alterada com sucesso, você será redirecionado para a tela de login ;)"
        );
        setTimeout(() => {
          navigate("/entrar");
        }, 5000);
      } else if (res.status === 404)
        setErrorMessage(
          `Não existe nenhuma conta vinculada ao email "${recoveryEmail}"`
        );
      else setErrorMessage("Não foi possível alterar sua senha");
    }
  };
  return (
    <Modal>
      <ModalHeader>
        <h1>alterar senha</h1>
        <p>
          Anote sua senha em algum local seguro para caso você esqueça novamente
          ;).
        </p>
      </ModalHeader>
      <Main>
        {successMessage && (
          <p className="text-align-center">{successMessage}</p>
        )}
        {errorMessage && <Error message={errorMessage} />}
        <InputWrapper className="flex direction-column min-gap">
          <Input
            placeholder="Nova Senha"
            onInput={(e) => setNewPassword(e.target.value)}
            type="password"
          />
          <Input
            placeholder="Repetir nova senha"
            onInput={(e) => setNewPasswordConfirmation(e.target.value)}
            type="password"
          />
          <Button text={"Atualizar senha"} $fullWidth action={updatePassword} />
        </InputWrapper>
      </Main>
    </Modal>
  );
};

const RecoveryEmailModal = ({
  setShowCodeValidationModal,
  recoveryEmail,
  setRecoveryEmail,
}) => {
  const [errorMessage, setErrorMessage] = useState();
  const requestRecoveryCodeNotification = async () => {
    const emailValidation = validateField(
      "email",
      recoveryEmail,
      true,
      false,
      null,
      null,
      emailRegex
    );
    if (!emailValidation.isValid) {
      setErrorMessage(emailValidation.message);
      return;
    }

    const res = await fetch(`${BASE_URL}account-recovery/${recoveryEmail}`);
    if (res.ok) {
      setShowCodeValidationModal(true);
    } else if (res.status == 403) {
      setErrorMessage(
        "Não é possível recuperar essa conta. Tente fazer login com o google"
      );
    } else if (res.status == 404) {
      setErrorMessage(
        `Não existe nenhuma conta vinculada ao email "${recoveryEmail}".`
      );
    }
  };

  return (
    <Modal>
      <ModalHeader>
        <h1>Recuperar conta</h1>
        <p>Digite o email da conta da qual deseja recuperar </p>
      </ModalHeader>
      <Main className="flex">
        <div>
          <div className="flex min-gap direction-column">
            {errorMessage && <Error message={errorMessage} />}
            <h2>
              Se você já tiver recebido o código, digite o email abaixo e clique
              em 'Já tenho o código'
            </h2>
            <div className="wrapper flex min-gap direction-column">
              <Input
                className="email-input"
                placeholder="Email"
                onInput={(e) => setRecoveryEmail(e.target.value)}
              />

              <Button
                text={"Enviar"}
                action={requestRecoveryCodeNotification}
                $fullWidth
              />

              <TextLink>
                <Link
                  onClick={() => {
                    const emailValidation = validateField(
                      "email",
                      recoveryEmail,
                      true,
                      false,
                      null,
                      null,
                      emailRegex
                    );
                    if (!recoveryEmail) {
                      setErrorMessage(
                        'Digite seu email antes de prosseguir e clique novamente em "Já tenho o código"'
                      );
                      return;
                    }
                    if (!emailValidation.isValid) {
                      setErrorMessage(emailValidation.message);
                      return;
                    }
                    setShowCodeValidationModal(true);
                  }}
                >
                  Já tenho o código
                </Link>
              </TextLink>
            </div>
          </div>
        </div>
        <IonIcon icon={mailOpenOutline} className="mail-icon" />
      </Main>
    </Modal>
  );
};
