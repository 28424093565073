import { IonIcon } from "@ionic/react";
import { locationSharp } from "ionicons/icons";
import React from "react";
import styled, { css } from "styled-components";
export const AddressStyle = styled.div`
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ scale, bgColor, color }) => css`
    font-size: ${20 * scale}px;
    gap: ${8 * scale}px;
    background-color: ${bgColor};
    color: ${color};
    padding: ${4 * scale}px;
    border-radius: 50px;
    .logo {
      font-size: ${28 * scale}px !important;
    }
  `}
  a img {
    width: 3rem;
    height: 3rem;
  }
`;
export default function BrandAddress({ styles, brand, scale }) {
  return (
    <>
      {brand.address.street !== "" ||
      brand.address.city !== "" ||
      brand.address.neighborhood !== "" ||
      brand.address.number !== "" ? (
        <AddressStyle
          scale={scale}
          className="flex align"
          bgColor={styles.priceBgColor}
          color={styles.priceTxtColor}
        >
          <IonIcon className="logo" icon={locationSharp} />
          <p>
            {brand.address.street !== "" && `Rua ${brand.address.street},`}{" "}
            {brand.address.number !== "" &&
              `N°
            ${brand.address.number}.`}{" "}
            {brand.address.neighborhood !== "" &&
              `
            ${brand.address.neighborhood}.`}{" "}
            {brand.address.city}
          </p>
        </AddressStyle>
      ) : (
        "fffffff"
      )}
    </>
  );
}
