import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import { MenuDescription, MenuTitle } from "./Toolbar";
import Product from "../../sharable/Product";
import Styles from "../../../OBJRep/Styles";
import ProductObj from "../../../OBJRep/Product";
import {
  NAME_ABOVE_PRODUCT_LAYOUT,
  NAME_BENEATH_PRODUCT_LAYOUT,
  TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
} from "../CanvasViewer/EncarteStyleConstants";

const Container = styled.div`
  height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
  main {
    width: 100%;

    gap: 3.6rem;
    flex-direction: column;
  }
`;

const LayoutWrapper = styled.div`
  height: 300px;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 3px;
  padding: 1.2rem 0;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  &:hover {
    border: 1px solid #d97706;
  }

  ${({ selected }) => {
    return (
      selected == true &&
      css`
        background-color: #d97706;
        color: #fff;
      `
    );
  }}
`;

const product = ProductObj(0, "Coca cola 2L", "6,49", "", "images/coca.png");
let styles = Styles();
styles.fontSize = "40";
styles.bgColor = "transparent";
styles.txtColor = "#fff";
styles.priceStyle = "roundAndSquare";
let styles2 = { ...styles };
let styles1 = { ...styles };
styles2.productLayout = TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT;
styles1.productLayout = NAME_BENEATH_PRODUCT_LAYOUT;
export default function ProductLayout({ props }) {
  const handleLayoutChange = (e) => {
    const layoutWrapper = e.target.closest(".layout");
    if (!layoutWrapper) return;
    const layout = layoutWrapper.getAttribute("data-layout-id");
    const encarteStyle = { ...props.encarteStyles };
    encarteStyle.productLayout = layout;
    props.updateEncarteStyles(encarteStyle);
  };
  return (
    <Container>
      <MenuDescription>
        Altere o layout do produto para as informações aparecerem como preferir!
      </MenuDescription>
      <MenuTitle>Layout do produto</MenuTitle>
      <main className="flex" onClick={handleLayoutChange}>
        <LayoutWrapper
          selected={
            props.encarteStyles.productLayout === undefined ||
            props.encarteStyles.productLayout === NAME_ABOVE_PRODUCT_LAYOUT
              ? true
              : false
          }
          className="layout"
          data-layout-id={NAME_ABOVE_PRODUCT_LAYOUT}
        >
          <Product scale={0.5} styles={styles} product={product} />
        </LayoutWrapper>
        <LayoutWrapper
          selected={
            props.encarteStyles.productLayout === NAME_BENEATH_PRODUCT_LAYOUT
              ? true
              : false
          }
          className="layout"
          data-layout-id={NAME_BENEATH_PRODUCT_LAYOUT}
        >
          <Product scale={0.5} styles={styles1} product={product} />
        </LayoutWrapper>
        <LayoutWrapper
          selected={
            props.encarteStyles.productLayout ===
            TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT
              ? true
              : false
          }
          className="layout"
          data-layout-id={TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT}
        >
          <Product scale={0.5} styles={styles2} product={product} />
        </LayoutWrapper>
      </main>
    </Container>
  );
}
