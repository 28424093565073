import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";

import { useOutletContext } from "react-router";
import { devices } from "../../../Devices";
import BrandMenuView from "./BrandMenuView";
import ModelsMenuView from "./ModelsMenuView";
import ProductsMenuView from "./ProductsMenuView.jsx";
import StylesMenuView from "./StylesMenuView.jsx";
import GridMenuView from "./GridMenuView.jsx";
import PricesMenuView from "./PricesMenuView.jsx";
import ProductLayout from "./ProductLayout.jsx";
import DateMenuView from "./DateMenuView.jsx";
import MyProductsView from "../../Views/MyProductsView.jsx";
export const PRODUCTS_VIEW = "0";
export const BRAND_VIEW = "1";
export const MODELS_VIEW = "2";
export const STYLES_VIEW = "3";
export const PRICE_VIEW = "4";
export const GRID_VIEW = "5";
export const PRODUCT_LAYOUT_VIEW = "6";
export const DATE_VIEW = "7";
export const MY_PRODUCTS_VIEW = "8";
const Container = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  background-color: #171717;
  color: #ecfeff;
  position: relative;


  @media ${devices.laptop} {

    grid-template-columns: 1fr;
    grid-template-rows: min-content; 1fr;
  }

    @media ${devices.tablet} {
    grid-template-columns: min-content 1fr;
    grid-template-rows: 1fr;
    grid-row: 2/3;
    
  }
`;

const Menu = styled.li`
  height: 100vh;
  overflow-y: scroll;
  list-style: none;
  background-color: #0a0a0a;
  border-bottom-right-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${devices.laptop} {
    flex-direction: row;
  }
  @media ${devices.tablet} {
    flex-direction: column;
  }
`;

export const MenuDescription = styled.span`
  font-size: 1.2rem;
  margin-bottom: 2rem;
  display: inline-block;
`;

const MenuItem = styled.ul`
  border-bottom: 1px solid #171717;
  text-transform: capitalize;
  font-size: 1.4rem;
  padding: 3.6rem 1.2rem;
  cursor: pointer;
  width: 100%;
  text-align: center;
  &&.current {
    color: #d97706;
    background-color: #171717;
  }
  position: relative;
`;
const MenuView = styled.div`
  padding: 2rem;
`;

export const MenuTitle = styled.h1`
  font-size: 3rem;
  font-family: "Titillium Web", sans-serif;
  grid-column: 1/-1;
  margin-bottom: 1.4rem;
  text-transform: capitalize;
`;

const Soon = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #d97706;
  font-size: 1rem;
  transform: rotate(45deg);
  padding: 0.2rem 0.4rem;
  color: #fff;
  z-index: 2;
`;

const EditPermissionMessage = styled.p`
  width: 100%;
  text-align: center;
  font-size: 1.4rem;
  display: block;
  margin-top: 2rem;
`;

//COMPONENT VERIFIED 31/05;
export default function Toolbar(props) {
  const [currentMenuView, setCurrentMenuView] = useState(PRODUCTS_VIEW);
  const menu = useRef();
  const { activeAccount, isAccountPremium } = useOutletContext();
  const watchCurrentMenuView = useEffect(() => {
    menu.current.querySelectorAll(".item").forEach((el, i) => {
      el.classList.remove("current");
      if (el.getAttribute("data-view") === currentMenuView)
        el.classList.add("current");
    });
  }, [currentMenuView]);

  return (
    <Container>
      <Menu
        ref={menu}
        onClick={(e) => {
          if (e.target.classList.contains("item"))
            setCurrentMenuView(e.target.getAttribute("data-view"));
        }}
      >
        <MenuItem data-view={PRODUCTS_VIEW} className="item">
          Produtos
        </MenuItem>
        <MenuItem data-view={MY_PRODUCTS_VIEW} className="item">
          Meus Produtos
        </MenuItem>
        <MenuItem data-view={DATE_VIEW} className="item">
          Data
        </MenuItem>
        <MenuItem data-view={STYLES_VIEW} className="item">
          estilo
        </MenuItem>
        <MenuItem data-view={MODELS_VIEW} className="item">
          Modelos
        </MenuItem>
        <MenuItem data-view={BRAND_VIEW} className="item">
          Marca
        </MenuItem>
        <MenuItem data-view={PRODUCT_LAYOUT_VIEW} className="item">
          Layout produto
        </MenuItem>

        <MenuItem data-view={PRICE_VIEW} className="item">
          Preços
          <Soon>Em andamento</Soon>
        </MenuItem>
        <MenuItem data-view={GRID_VIEW} className="item">
          Grid
          <Soon>em breve</Soon>
        </MenuItem>
      </Menu>

      {activeAccount ? (
        <MenuView isAccountPremium={isAccountPremium}>
          {currentMenuView === PRODUCTS_VIEW ? (
            <ProductsMenuView props={props} />
          ) : currentMenuView === DATE_VIEW ? (
            <DateMenuView props={props} />
          ) : currentMenuView === BRAND_VIEW ? (
            <BrandMenuView
              props={props}
              updateBrand={props.updateBrand}
              brand={props.brand}
            />
          ) : currentMenuView === MODELS_VIEW ? (
            <ModelsMenuView
              updateCurrentModel={props.updateCurrentModel}
              models={props.availableModels}
            />
          ) : currentMenuView === STYLES_VIEW ? (
            <StylesMenuView props={props} />
          ) : currentMenuView === PRICE_VIEW ? (
            <PricesMenuView props={props} />
          ) : currentMenuView === PRODUCT_LAYOUT_VIEW ? (
            <ProductLayout props={props} />
          ) : currentMenuView === MY_PRODUCTS_VIEW ? (
            <MyProductsView
              updateEncarteProducts={props.updateEncarteProducts}
              encarteProducts={props.encarteProducts}
            />
          ) : (
            <GridMenuView props={props} />
          )}
        </MenuView>
      ) : (
        <EditPermissionMessage>
          Você ainda pode visualizar e baixar o seu encarte :)
        </EditPermissionMessage>
      )}
    </Container>
  );
}
