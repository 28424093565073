import React, { useEffect, useRef, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import {
  FEED_INSTAGRAM,
  STORIES,
  CANVAS_WRAPPER_PRINT_TARGET_CLASSNAME,
  CANVAS_VIWER_TARGET_CLASSNAME,
  PANFLETO,
} from "./EncarteStyleConstants";

import Loading from "../../sharable/Loading";

import FeedCanvas from "./Canvas/FeedCanvas";
import StoriesCanvas from "./Canvas/StoriesCanvas";
import PanfletoCanvas from "./Canvas/PanfletoCanvas";
import { Canvas } from "./Canvas/CanvasGeneralStyles";
const Container = styled.div`
  border-top: 1px solid #d9d9d9;
  position: relative !important;
  padding: 2rem 4.2rem;
  display: flex;
  flex-direction: column;
  height: min-content;
  //overflow: hidden;
`;

const TimeOutAnimation = keyframes`
  to {
    width: 0%;
  }
`;

const PopUpTimeOut = styled.div`
  width: 100%;
  height: 5px;
  background-color: #d97706;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  animation: ${TimeOutAnimation} 15s linear;
`;

const FormatSelector = styled.select`
  background-color: transparent;
  border: none;
  font-size: 1.4rem;
  text-transform: capitalize;
  cursor: pointer;
  &:focus-visible {
    outline-width: 0;
  }

  option {
    cursor: pointer;
  }
`;

const CanvasWrapper = styled.div`
width: min-content;
height; min-content;
  @media print {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 0;
  }
`;

const PopUp = styled.div`
  position: fixed;
  top: 10rem;
  right: 4.2rem;
  width: 25rem;
  border-radius: 5px;
  background-color: #171717;
  color: #fff;
  line-height: 1.3;
  font-size: 1.2rem;
  z-index: 10;
  box-shadow: 0px 10px 15px rgba(1, 1, 1, 0.2);

  button {
    all: unset;
    margin-top: 1rem;
    cursor: pointer;
    padding: 0.6rem 1.2rem;
    border: #d9d9d9 1px solid;
    align-self: end;
    border-radius: 5px;
    transition: all 300ms ease-in-out;
    text-transform: capitalize;
    &:hover {
      border-color: transparent;
      color: #d97706;
    }
  }

  .txt-button-wrapper {
    padding: 1.2rem;
  }
`;

export default function SocialMediaCanvasViewer({
  scale,
  maxProducts,
  currentModel,
  brand,
  encarteProducts,
  styles,
  updateEncarteStyles,
  encarte,
}) {
  const [showPopUp, setShowPopUp] = useState(false);
  const watchMaxProducts = useEffect(() => {
    if (encarteProducts.length >= maxProducts) setShowPopUp(true);
    const timer = setTimeout(() => {
      setShowPopUp(false);
    }, 15000);
    return () => clearTimeout(timer);
  }, [encarteProducts.length]);

  return (
    <Container className={`flex align ${CANVAS_VIWER_TARGET_CLASSNAME}`}>
      {styles && brand && currentModel ? (
        <>
          {showPopUp ? (
            <PopUp className="flex direction-column">
              <div className="txt-button-wrapper flex direction-column">
                <p>
                  Você alcançou a quantidade máxima recomendada de produtos,
                  passar disso irá comprometer a qualidade do encarte. Crie
                  outro encarte para continuar adicionando.
                </p>
                <button onClick={() => setShowPopUp(false)}>entendi</button>
              </div>
              <PopUpTimeOut />
            </PopUp>
          ) : (
            ""
          )}
          <FormatSelector
            onClick={(e) => {
              const s = { ...styles };
              s.format = e.target.value;
              updateEncarteStyles(s);
            }}
            defaultValue={styles.format}
          >
            <option value={FEED_INSTAGRAM}>{FEED_INSTAGRAM}</option>
            <option value={STORIES}>{STORIES}</option>
            <option value={PANFLETO}>{PANFLETO}</option>
          </FormatSelector>

          <CanvasWrapper className={CANVAS_WRAPPER_PRINT_TARGET_CLASSNAME}>
            {styles.format === FEED_INSTAGRAM ? (
              <FeedCanvas
                scale={scale}
                styles={styles}
                products={encarteProducts}
                brand={brand}
                model={currentModel}
                encarte={encarte}
              />
            ) : styles.format === STORIES ? (
              <StoriesCanvas
                scale={scale}
                styles={styles}
                products={encarteProducts}
                brand={brand}
                model={currentModel}
                encarte={encarte}
              />
            ) : (
              <PanfletoCanvas
                scale={scale}
                styles={styles}
                products={encarteProducts}
                brand={brand}
                model={currentModel}
                encarte={encarte}
              />
            )}
          </CanvasWrapper>
        </>
      ) : (
        <Loading />
      )}
    </Container>
  );
}

export function copy(src) {
  var dst = new ArrayBuffer(src.byteLength);
  new Uint8Array(dst).set(new Uint8Array(src));
  return dst;
}
