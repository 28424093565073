import React, { useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import {
  BrandLogo,
  Canvas,
  Footer,
  GridProducts,
  Header,
} from "../Canvas/CanvasGeneralStyles";
import Product from "../../../sharable/Product";
import ExpirationDate, {
  ExpirationDateStyle,
  Text,
} from "../../../sharable/ExpirationDate";
import {
  CANVAS_TARGET_CLASSNAME,
  FEED_INSTAGRAM_DIM,
  MAX_PRODUCTS_FEED_FORMAT,
} from "../EncarteStyleConstants";
import BrandAddress, { AddressStyle } from "../../../sharable/BrandAddress";

const FeedCanvasContainer = styled(Canvas)`
  ${({ scale }) => css`
    background-position-y: ${-220 * scale}px;
  `}
`;

const FeedHeader = styled(Header)``;

const FeedGridProducts = styled(GridProducts)``;

const FeedFooter = styled(Footer)`
  padding: 0;
  width: 100%;
  flex-direction: column;
  display: flex;
  line-height: 1.3;
  justify-content: space-between;
     ${AddressStyle} {
      width: 100%;
      border-radius: 0;
      font-weight: bold;
      text-transform: uppercase;
      margin-top: 0;
      ${({ styles }) => css`
        color: ${styles.priceBgColor};
        background-color: ${styles.priceTxtColor};
      `}
      ${({ scale }) => css`
        p {
          font-size: ${20 * scale}px !important;
          display: inline-block;
          margin-top: 0;
        }
        .logo {
          font-size: ${26 * scale}px !important;
        }
      `}
    }
  ${ExpirationDateStyle}, p {
    width: 100%;
    text-align: center;
    margin: 0;
    ${({ scale }) => css`
      font-size: ${20 * scale}px;
    `}
  align-items: center;

`;
export default function FeedCanvas({
  styles,
  products,
  scale,
  brand,
  encarte,
  model,
}) {
  return (
    <FeedCanvasContainer
      scale={scale}
      styles={styles}
      model={model}
      dimensions={FEED_INSTAGRAM_DIM}
      className={CANVAS_TARGET_CLASSNAME}
    >
      <FeedHeader>
        <BrandLogo
          logo={brand.logoURLTmp}
          scale={scale}
          logoSize={styles.logoSize}
          styles={styles}
        />
      </FeedHeader>

      <FeedGridProducts
        grid={calcFeedGrid(
          products.length > MAX_PRODUCTS_FEED_FORMAT
            ? MAX_PRODUCTS_FEED_FORMAT
            : products.length
        )}
        styles={styles}
        scale={scale}
      >
        {products.slice(0, 8).map((product) => (
          <Product product={product} scale={scale} styles={styles} />
        ))}
      </FeedGridProducts>

      <FeedFooter model={model} scale={scale} styles={styles}>
        <ExpirationDate
          endDate={encarte.endDate}
          startDate={encarte.startDate}
          styles={styles}
          scale={scale}
        />
        <BrandAddress brand={brand} scale={scale} styles={styles} />
      </FeedFooter>
    </FeedCanvasContainer>
  );
}

const calcFeedGrid = (productsSize) => {
  let grid = { columns: "", rows: "", highLight: undefined };

  const evenCount = productsSize % 2 === 0 ? productsSize : productsSize + 1;
  const MAX_COLUMNS = 6;
  const MAX_ROWS = 2;

  if (productsSize === 1) {
    grid.columns = 1;
    grid.rows = 1;
  } else if (productsSize === 2) {
    grid.columns = 2;
    grid.rows = 1;
  } else {
    for (let j = 0; j <= MAX_ROWS; j++) {
      for (let i = 0; i <= MAX_COLUMNS; i++) {
        if (i * j === evenCount) {
          grid.columns = i;
          grid.rows = j;
          continue;
        }
      }
    }
  }
  grid.highLight =
    grid.columns * grid.rows !== productsSize && grid.columns > 3
      ? "column"
      : grid.columns * grid.rows !== productsSize && grid.columns <= 3
      ? "row"
      : undefined;
  return grid;
};
