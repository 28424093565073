import React from "react";
import styled, { css } from "styled-components";
import { MenuDescription, MenuTitle } from "./Toolbar";
import { useOutletContext } from "react-router";
import PremiumContentProtectiveLayer from "../../sharable/PremiumContentProtectiveLayer";
import ProductLayout from "./ProductLayout";

const Container = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
  ${({ isAccountPremium }) =>
    !isAccountPremium &&
    css`
      main {
        filter: blur(3px);
      }
    `}
`;
export default function GridMenuView({ props }) {
  const { activeAccount, isAccountPremium } = useOutletContext();
  return (
    <Container isAccountPremium={isAccountPremium}>
      {!isAccountPremium && <PremiumContentProtectiveLayer />}
      <main>
        <MenuDescription>
          Em breve você poderá customizar a grade de produtos
        </MenuDescription>
        <MenuTitle>Grade de Produtos</MenuTitle>
      </main>
    </Container>
  );
}
