export default function ProductObj(
  id = 0,
  name = "Nome do produto",
  price = "00.00",
  imageURL = "",
  imageURLTmp = "",
  unityMeasurement = "unid"
) {
  return {
    id: id,
    name: name,
    price: price,
    imageURL: imageURL,
    unityMeasurement: unityMeasurement,
    imageURLTmp: imageURLTmp,
  };
}
