import React from "react";
import styled, { css } from "styled-components";
import Price from "./Price";
import {
  NAME_ABOVE_PRODUCT_LAYOUT,
  NAME_BENEATH_PRODUCT_LAYOUT,
  TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT,
} from "../WorkSpace/CanvasViewer/EncarteStyleConstants";

const Container = styled.div`
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  display: flex;

  overflow: hidden;
  ${({ productLayout, scale }) => {
    let style;
    if (productLayout === TWO_COLUMNS_NAME_LEFT_PRODUCT_LAYOUT) {
      style = css`
        flex-direction: row-reverse;
        position: relative;
        .product-name {
          align-self: start !important;
          margin-top: ${24 * scale}px;
        }
        .price {
          left: 0%;
          right: auto;
          position: absolute;
          transform: translate(50%, 0);
        }
        .product-image {
          position: unset;
          background-size: contain;
        }
      `;
    } else if (productLayout === NAME_BENEATH_PRODUCT_LAYOUT) {
      style = css`
        flex-direction: column;
        align-items: center;
        .product-name {
          border-bottom-right-radius: ${30 * scale}px;
          border-bottom-left-radius: ${30 * scale}px;
        }
      `;
    } else {
      style = css`
        flex-direction: column-reverse;
        .product-name {
          border-top-right-radius: ${30 * scale}px;
          border-top-left-radius: ${30 * scale}px;
        }
      `;
    }

    return style;
  }}

  ${({ bgColor, scale }) => {
    if (bgColor)
      return css`
        background-color: ${bgColor ? bgColor : "#991B1B"};
        border-radius: ${30 * scale}px !important;
      `;
  }}
  .name-price {
    align-items: center;
    gap: 2.6rem;
  }
`;

export const Image = styled.div`
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  ${({ url, hover, height, pId, backgroundSize }) => {
    return css`
      background-image: url("${url}");
      cursor: pointer;
      height: ${height ? height : `100%`};
      background-size: ${backgroundSize ? backgroundSize : "auto 100%"};
      ${hover
        ? `&&:hover {
        background-color: rgba(1,1,1,.15)
      }`
        : ""};

      ${pId > 6 ? "background-size: contain;" : ""}
    `;
  }}
`;

const Name = styled.span`
  ${({ fontSize, scale, bgColor, txtColor }) => {
    return css`
      font-size: ${fontSize * scale}px;
      background-color: ${bgColor};
      color: ${txtColor ? txtColor : "black"};
    `;
  }}
  bottom: 0;
  padding: 2%;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1;
  align-self: center;
  text-align: center;
  display: block;
  opacity: 1.5;

  width: 100%;
`;

export default function Product({ styles, product, scale }) {
  return (
    <Container
      productLayout={styles.productLayout}
      font={styles.fontFamily}
      txtColor={styles.txtColor}
      bgColor={styles.bgColor}
      scale={scale}
      className="product"
      key={`grid-product-${product.id}`}
    >
      <Image
        scale={scale}
        url={product.imageURLTmp}
        bgColor={styles.bgColor}
        className="product-image"
        pId={product.id}
      >
        <Price
          scale={scale}
          price={product.price}
          unity={product.unityMeasurement}
          styles={styles}
        />
      </Image>

      <Name
        productLayout={styles.productLayout}
        scale={scale}
        fontSize={styles.fontSize}
        bgColor={styles.bgColor}
        txtColor={styles.txtColor}
        className="product-name"
      >
        {product.name}
      </Name>
    </Container>
  );
}
