import React, { useContext, useState } from "react";
import styled, { css } from "styled-components";
import { MenuDescription, MenuTitle } from "./Toolbar";
import {
  BG_COLORS,
  Picker,
  Selector,
  FONT_FAMILIES,
} from "../CanvasViewer/EncarteStyle";
import Switch from "../../sharable/Switch";
import {
  FONT_SIZES,
  LOGO_SIZE_LARGE,
  LOGO_SIZE_MEDIUM,
  LOGO_SIZE_SMALL,
  ROUND_LOGO_WITH_BORDER,
  ROUND_LOGO_WITHOUT_BORDER,
  SQUARE_LOGO_WITH_BORDER,
  SQUARE_LOGO_WITHOUT_BORDER,
} from "../CanvasViewer/EncarteStyleConstants";
const Container = styled.div`
  padding-bottom: 10rem;

  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
`;
const Styles = styled.div`
  gap: 3rem;
`;

const LogoStyle = styled.div`
  h2 {
    font-size: 2.4rem;
    text-transform: uppercase;
    margin-bottom: 3rem;
  }
  h3 {
    font-size: 1.8rem;
    text-transform: uppercase;
    margin-bottom: 3rem;
  }
`;

const LogoStyleList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  gap: 2rem;
`;

const LogoSize = styled.div`
  margin-top: 3.2rem;
  cursor: pointer;

  .size {
    padding: 1.2rem 3.2rem;
    border: 1px solid #d97706;
    transition: all 300ms ease-in-out;
    font-size: 1.2rem;
    text-transform: capitalize;
    font-weight: bold;
  }

  .size:hover,
  .size.selected {
    border-color: transparent;
    background-color: #d97706;
  }
`;
export default function StylesView({ props }) {
  const updateEncarteStyle = (style, value) => {
    const styles = { ...props.encarteStyles };
    styles[style] = value;
    props.updateEncarteStyles(styles);
  };

  const handleLogoUpdate = (e) => {
    if (!e.target.classList.contains("logo")) return;
    const wrapper = e.target.closest(".logos-wrapper");
    wrapper.querySelectorAll(".logo").forEach((n) => {
      n.classList.remove("selected");
    });
    e.target.classList.add("selected");
  };

  const handleLogoSizeUpdate = (e) => {
    if (!e.target.classList.contains("size")) return;
    const size = e.target.getAttribute("data-logo-size");
    updateEncarteStyle("logoSize", size);
    const wrapper = e.target.closest(".size-wrapper");
    wrapper
      .querySelectorAll(".size")
      .forEach((e) => e.classList.remove("selected"));
    e.target.classList.add("selected");
  };
  return (
    <Container>
      <MenuDescription>
        Estilize o encarte da maneira que preferir. Altere cores de fundo e de
        texto, e escolha dentre as fontes disponiveis.
      </MenuDescription>
      <MenuTitle>estilo do encarte</MenuTitle>
      <Styles className="flex direction-column">
        <Selector
          options={FONT_SIZES}
          txt="Tamanho da fonte"
          callback={(c) => {
            updateEncarteStyle("fontSize", c);
          }}
          selected={props.encarteStyles.fontSize}
        />
        <Selector
          options={FONT_FAMILIES}
          txt="Estilo da fonte"
          callback={(c) => {
            updateEncarteStyle("fontFamily", c);
          }}
          selected={props.encarteStyles.fontFamily}
        />
        <Picker
          color={props.encarteStyles.txtColor}
          txt="cor geral do texto"
          colorsArr={BG_COLORS}
          callback={(c) => {
            updateEncarteStyle("txtColor", c);
          }}
          defaultColor="black"
        />
        <Picker
          optional={true}
          color={props.encarteStyles.bgColor}
          txt="cor de fundo do produto (opcional)"
          colorsArr={BG_COLORS}
          callback={(c) => {
            updateEncarteStyle("bgColor", c);
          }}
          defaultColor="#1E40AF"
        />
        <Picker
          color={props.encarteStyles.priceTxtColor}
          txt="cor de texto do preço (opcional)"
          colorsArr={BG_COLORS}
          callback={(c) => {
            updateEncarteStyle("priceTxtColor", c);
          }}
          optional={true}
          defaultColor="#fff"
        />
        <Picker
          color={props.encarteStyles.priceBgColor}
          txt="cor de fundo do preço (opcional)"
          colorsArr={BG_COLORS}
          callback={(c) => {
            updateEncarteStyle("priceBgColor", c);
          }}
          optional={true}
          defaultColor="#991b1b"
        />
        <LogoStyle>
          <h2>Logo</h2>
          <LogoStyleList
            className="logo-style-wrapper"
            onClick={handleLogoUpdate}
          >
            <LogoStyleComponent
              logo={props.brand.logoURLTmp}
              text="redonda com borda"
              componentLogoStyle={ROUND_LOGO_WITH_BORDER}
              encarteLogoStyle={props.encarteStyles.logoStyle}
              updateEncarteStyle={updateEncarteStyle}
              defaultLogoStyle={true}
            />
            <LogoStyleComponent
              logo={props.brand.logoURLTmp}
              text="redonda sem borda"
              componentLogoStyle={ROUND_LOGO_WITHOUT_BORDER}
              encarteLogoStyle={props.encarteStyles.logoStyle}
              updateEncarteStyle={updateEncarteStyle}
              defaultLogoStyle={false}
            />
            <LogoStyleComponent
              logo={props.brand.logoURLTmp}
              text="Quadrada com borda"
              componentLogoStyle={SQUARE_LOGO_WITH_BORDER}
              encarteLogoStyle={props.encarteStyles.logoStyle}
              updateEncarteStyle={updateEncarteStyle}
            />
            <LogoStyleComponent
              logo={props.brand.logoURLTmp}
              text="quadrada sem borda"
              componentLogoStyle={SQUARE_LOGO_WITHOUT_BORDER}
              encarteLogoStyle={props.encarteStyles.logoStyle}
              updateEncarteStyle={updateEncarteStyle}
            />
          </LogoStyleList>
          <LogoSize className="logo-size">
            <h3>tamanho</h3>
            <div
              className="size-wrapper flex space-around"
              onClick={handleLogoSizeUpdate}
            >
              <div
                className={`size ${
                  props.encarteStyles.logoSize === LOGO_SIZE_SMALL && "selected"
                }`}
                data-logo-size={LOGO_SIZE_SMALL}
              >
                Pequena
              </div>
              <div
                className={`size ${
                  props.encarteStyles.logoSize === LOGO_SIZE_MEDIUM ||
                  (!props.encarteStyles.logoSize && "selected")
                }`}
                data-logo-size={LOGO_SIZE_MEDIUM}
              >
                média
              </div>
              <div
                className={`size ${
                  props.encarteStyles.logoSize === LOGO_SIZE_LARGE && "selected"
                }`}
                data-logo-size={LOGO_SIZE_LARGE}
              >
                grande
              </div>
            </div>
          </LogoSize>
        </LogoStyle>
      </Styles>
    </Container>
  );
}

const LogoStyleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: min-content;
  height: min-content;

  span {
    margin-top: 1rem;
    font-size: 1.4rem;
    text-align: center;
    &::first-letter {
      text-transform: uppercase;
    }
  }
`;
const LogoImage = styled.div`
  border: 0px solid transparent;
  &:hover {
    border-color: #d97706;
    border-width: 4px;
  }

  transition: borderColor 200ms ease-in-out;
  width: 10rem;
  height: 10rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  ${({ encarteLogoStyle, componentLogoStyle, defaultLogoStyle, logo }) => {
    const isCurrentLogoStyle =
      encarteLogoStyle === componentLogoStyle ||
      (!encarteLogoStyle && defaultLogoStyle)
        ? true
        : false;
    const hasBorderRadius =
      componentLogoStyle === ROUND_LOGO_WITH_BORDER ||
      componentLogoStyle === ROUND_LOGO_WITHOUT_BORDER;
    return css`
      ${isCurrentLogoStyle && "border-color: #d97706; border-width: 4px;"}
      ${hasBorderRadius && "border-radius: 50%; "}
            background-image: url(${logo});
    `;
  }}
`;

const LogoStyleComponent = ({
  logo,
  text,
  encarteLogoStyle,
  componentLogoStyle,
  defaultLogoStyle = false,
  updateEncarteStyle,
}) => {
  return (
    <LogoStyleWrapper className="wrapper flex direction-column">
      <LogoImage
        componentLogoStyle={componentLogoStyle}
        encarteLogoStyle={encarteLogoStyle}
        defaultLogoStyle={defaultLogoStyle}
        logo={logo}
        onClick={() => {
          updateEncarteStyle("logoStyle", componentLogoStyle);
        }}
      />
      <span>{text}</span>
    </LogoStyleWrapper>
  );
};
