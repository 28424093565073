import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import {
  MenuDescription,
  MenuTitle,
} from "../WorkSpace/ToolBarMenuViews/Toolbar";
import { useOutletContext } from "react-router";
import {
  convertProductsImagesToDataURL,
  searchIntoCollection,
  toDataURL,
} from "../../services/Utils";
import Button from "../sharable/ActionButton";
import { Input } from "../sharable/InputStyle";
import { Selector } from "../WorkSpace/CanvasViewer/EncarteStyle";
import { UNITYS } from "../WorkSpace/CanvasViewer/EncarteStyleConstants";
import Loading from "../sharable/Loading";
import { searchProductImages } from "../../services/ProductImageService";
import { Image } from "../sharable/Product";
import { BoxImage } from "../WorkSpace/ToolBarMenuViews/ProductsMenuView";
import ProductObj from "../../OBJRep/Product";
import PopUp from "../sharable/PopUp";
import { IonIcon } from "@ionic/react";
import { close } from "ionicons/icons";
import PremiumContentProtectiveLayer from "../sharable/PremiumContentProtectiveLayer";

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;

  .query-input {
    width: 100%;
    margin-bottom: 3rem;
  }

  ${({ isAccountPremium }) =>
    !isAccountPremium &&
    css`
      overflow-y: unset;
      overflow-x: unset;

      main {
        filter: blur(3px);
      }
    `}
`;

const List = styled.ul`
  gap: 3rem;
  width: 100%;
  position: relative;
`;

const ProductItem = styled.li`
  align-items: center;
  width: 100%;
  justify-content: space-between;
  font-size: 1.4rem;
  font-weight: bold;
  transition: all 300ms ease-in-out;
  cursor: pointer;
  padding: 0 1.2rem;
  &:hover {
    background-color: #d97706;
  }
  span:first-letter {
    text-transform: uppercase;
  }
  img {
    width: 5rem;
    height: 5rem;
  }

  .name-price-wrapper {
    gap: 3rem;
  }
`;

const ProductFormWrapper = styled.div`
  overflow: hidden;
  margin: 1.6rem 0;
  position: relative;

  .close-form-span {
    font-size: 1.2rem;
    border: 1px solid #fff;
    padding: 0.4rem 0.8rem;
    width: 40%;
    justify-content: space-around;
    margin-bottom: 1.2rem;
    font-weight: bold;
    transition: all 300ms;
    cursor: pointer;
    &:hover {
      background-color: #d97706;
      border-color: transparent;
    }
  }
`;
const NewProductForm = styled.div`
  transform: translateY(-100%);
  transition: all 400ms ease-in-out;
  height: 0;
  ${({ showForm }) =>
    showForm &&
    css`
      height: min-content;
      transform: translateY(0);
    `}
`;

export default function MyProductsView({
  encarteProducts,
  updateEncarteProducts,
}) {
  const { token, isAccountPremium } = useOutletContext();
  const [userProducts, setUserProducts] = useState();
  const [showForm, setShowForm] = useState(false);
  const [isUserTyping, setIsUserTyping] = useState(false);
  const [productNameInput, setProductNameInput] = useState("");
  const [toUploadProduct, setToUploadProduct] = useState();
  const [searchImageResults, setSearchImageResults] = useState();
  const [
    showProductCreationSuccessMessage,
    setShowProductCreationSuccessMessage,
  ] = useState(false);
  const addProductToEncarte = async (product) => {
    const copy = [...encarteProducts];
    let productExists = false;
    copy.forEach((cp) => {
      if (cp.name === product.name) productExists = true;
    });
    if (productExists) return;
    product.imageURLTmp = await toDataURL(product.imageURL, token);
    copy.push(product);
    updateEncarteProducts(copy);
  };
  const watchUserTyping = useEffect(() => {
    setIsUserTyping(true);
    const timeout = setTimeout(() => {
      if (!productNameInput) return;

      setIsUserTyping(false);
    }, 1200);
    return () => clearTimeout(timeout);
  }, [productNameInput]);
  const loadUserProducts = useEffect(() => {
    const perform = async () => {
      const DOMAIN = process.env.REACT_APP_SERVER_BASE_URL;
      const res = await fetch(`${DOMAIN}products`, {
        headers: {
          Authorization: token,
        },
      });
      const json = await res.json();
      setUserProducts(json);
    };
    perform();
  }, []);

  const createNewProduct = async () => {
    if (!toUploadProduct.name) return;
    const DOMAIN = process.env.REACT_APP_SERVER_BASE_URL;
    const res = await fetch(`${DOMAIN}products`, {
      headers: { Authorization: token, "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify(toUploadProduct),
    });
    if (res.ok) {
      const userProductsCopy = [...userProducts];
      userProductsCopy.push(toUploadProduct);
      setUserProducts(userProductsCopy);
      setToUploadProduct(null);
      setShowProductCreationSuccessMessage(true);
      clearFormFields();
    }
  };
  const handleImageClick = (image) => {
    if (!image) return;
    handleFormUpdate("imageURL", image.link);
  };

  const handleFormUpdate = (prop, value) => {
    const copy = { ...toUploadProduct };
    copy[prop] = value;
    setToUploadProduct(copy);
  };

  const clearFormFields = () => {
    document.querySelector(".product-name").value = "";
    document.querySelector(".product-price").value = "";
    setSearchImageResults([]);
  };
  return (
    <Container isAccountPremium={isAccountPremium}>
      {!isAccountPremium && <PremiumContentProtectiveLayer />}
      <main>
        <MenuDescription>
          Você pode criar encartes adicionando produtos já cadastrados aqui!
        </MenuDescription>

        {showForm ? (
          <Button
            $fullWidth
            text="Salvar novo produto"
            color="#fff"
            width="calc(100% - 3px)"
            action={async () => {
              await createNewProduct();
              setShowForm((t) => false);
            }}
            size="1.4"
          />
        ) : (
          <Button
            $NoBgColor
            $fullWidth
            text="Registrar novo produto +"
            color="#fff"
            width="calc(100% - 3px)"
            action={() => {
              setToUploadProduct(ProductObj());
              setShowForm((t) => true);
            }}
            size="1.4"
          />
        )}

        <ProductFormWrapper>
          <PopUp
            setShowPopUp={setShowProductCreationSuccessMessage}
            text={"Produto registrado com sucesso!"}
            showPopUp={showProductCreationSuccessMessage}
            buttonText={"Ok"}
            position="relative"
            top="0rem"
            right="2rem"
          />
          <NewProductForm
            showForm={showForm}
            className="flex direction-column min-gap"
          >
            <span
              className="close-form-span flex align"
              onClick={() => {
                setShowForm(false);
                clearFormFields();
              }}
            >
              Fechar formulário <IonIcon icon={close} />
            </span>
            <div className="product-image-wrapper flex justify align">
              {toUploadProduct && toUploadProduct.imageURL != "" ? (
                <Image
                  url={toUploadProduct.imageURL}
                  height="9rem"
                  backgroundSize="contain"
                />
              ) : (
                "A imagem do produto aparecerá aqui"
              )}
            </div>

            <Input
              type="text"
              className="product-name"
              placeholder="Nome do produto..."
              onChange={(e) => {
                handleFormUpdate("name", e.target.value);
              }}
            />
            <Input
              type="text"
              placeholder="Valor do produto... Ex: 5,99"
              className="product-price"
              onChange={(e) => {
                handleFormUpdate("price", e.target.value);
              }}
            />
            <Selector
              callback={(value) => handleFormUpdate("unityMeasurement", value)}
              options={UNITYS}
              selected={UNITYS[0]}
              txt="Valor por"
            />
            <BoxImage>
              {searchImageResults &&
                searchImageResults.map((image, id) => (
                  <Image
                    onClick={() => {
                      handleImageClick(image);
                    }}
                    url={`${image.link}`}
                    height="9rem"
                    hover={true}
                    key={`image-${id}`}
                    backgroundSize="contain"
                  />
                ))}
            </BoxImage>
            <Button
              color={"#fff"}
              $fullWidth
              size="1.4"
              $NoBgColor
              text={"Buscar imagens"}
              width="calc(100% - 2px)"
              action={async () => {
                if (!toUploadProduct || toUploadProduct.name.length < 3) return;
                const results = await searchProductImages(
                  toUploadProduct.name,
                  token,
                  0
                );
                setSearchImageResults(results);
              }}
            />
          </NewProductForm>
        </ProductFormWrapper>
        <MenuTitle>Meus produtos</MenuTitle>
        <Input
          className="query-input"
          type="text"
          placeholder="Digite aqui para buscar um produto..."
          onChange={(e) => {
            setProductNameInput(e.target.value);
          }}
        />
        <List className="flex direction-column justify">
          {isUserTyping && productNameInput ? (
            <Loading
              $NoBg
              txt={`Buscando produto ${productNameInput}...`}
              color="#fff"
            />
          ) : productNameInput != "" && !isUserTyping ? (
            searchIntoCollection(productNameInput, "name", userProducts).map(
              (p) => <Product product={p} addProduct={addProductToEncarte} />
            )
          ) : userProducts ? (
            userProducts.map((product) => (
              <Product product={product} addProduct={addProductToEncarte} />
            ))
          ) : (
            <Loading $NoBg color="#fff" txt="Carregando seus produtos..." />
          )}
        </List>
      </main>
    </Container>
  );
}

const Product = ({ product, addProduct }) => {
  return (
    <ProductItem
      className="flex"
      onClick={() => {
        addProduct(product);
      }}
    >
      <div className="name-price-wrapper flex">
        <span>{product.name}</span>
        <div className="price-unity-wrapper flex direction-column">
          <span>R${product.price}</span>
          <span>{product.unityMeasurement}</span>
        </div>
      </div>
      <img src={product.imageURLTmp} alt="" />
    </ProductItem>
  );
};
